<template>
  <div class="horizontal-padding">
    <h1>Forgot Username or Password</h1>

    <alert
      v-if="formSuccess"
      variant="success"
    >
      <p>
        We've sent an email to you. You should be receiving it shortly.
      </p>

      <p>
        If you don't receive the email, please make sure you've entered the
        correct email address, and check your spam folder.
      </p>
    </alert>

    <template v-else>
      <p class="text-center">
        Forgot your username or password? Enter your email address below and
        we'll email you your username and instructions for resetting your
        password.
      </p>

      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            id="email"
            v-model="formFields.email"
            type="email"
            required
          >
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Processing
            <spinner />
          </template>

          <template v-else>
            Email Me
          </template>
        </button>
      </form>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    formFields: {
      email: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/password_reset/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .alert {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    max-width: 100%;

    p:last-child {
      margin-bottom: 0;
    }
  }

  form {
    margin: 0 auto 1rem;
    width: 290px;
  }
</style>
