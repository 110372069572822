
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    formFields: {
      email: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/password_reset/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
